import logo from '../images/logo-logis.webp';

import Menu from "./menu";
import Services from "./services";
import Book from "./book";
import Rooms from "./rooms";
import Mediateur from "./Mediateur"
import Mentions from "./Mentions"
import Annulation from "./Annulation"

import img1 from "../images/img1.jpg";
import img3 from "../images/img3.jpg";
import pmr from "../images/pmr.jpeg";
import img2 from "../images/img2.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faPhoneAlt, faEnvelope, faPersonCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import Review from "./review";
import {useState} from "react";
import './App.css';

function App() {

    const [room, setRoom] = useState('')
    const [open, setOpen] = useState(false)
    const [openMentions, setOpenMentions] = useState(false)
    const [openAnnulation, setOpenAnnulation] = useState(false)
    function handleChange(e) {
        setRoom(e)
    }

   const roomsData = [
    {
      image: img1,
      title: "Chambre double",
      features: [
        "Idéale pour une personne seule ou un couple",
        "Accès gratuit illimité à internet via wifi",
        "Petit déjeuner en option pour 8€ par personne",
      ],
      rate: 64,
    },
    {
      image: img3,
      title: "Chambre twin",
      features: [
        "2 grands lits séparés, idéale pour partager avec des amis ou des collègues",
        "Accès gratuit illimité à internet via wifi",
        "Petit déjeuner en option pour 8€ par personne",
      ],
      rate: 66,
    },
    {
      image: pmr,
      title: "Chambre pour personnes à mobilité réduite",
      features: [
        "Nous disposons de chambres adaptées aux personnes à mobilité réduite",
        "Accès gratuit illimité à internet via wifi",
        "Petit déjeuner en option pour 8€ par personne",
      ],
      rate: 66,
    },
    {
      image: img2,
      title: "Chambres Familiales",
      features: [
        "Une chambre twin et une chambre double avec une porte communicante, idéale pour les familles avec des enfants",
        "Accès gratuit illimité à internet via wifi",
        "Petit déjeuner en option pour 8€ par personne",
      ],
      rate: 99,
    },
  ];

  return (
    <div className="App">
      <header className="header" id="header">
        <div className="head-top">
          <div className="site-name">

      {/*            <a href="https://www.logishotels.com/fr/tarifs-et-disponibilites-chambre?id=346402"><img
                     src={logo} alt="logis de france"/> </a>*/}
          <a href="/">
        
              <span>Hotel du marais</span>
            </a>

          </div>
          <Menu />

        </div>

        <div className="head-bottom flex">
          <h2>Hotel du Marais</h2>
          <p>L’hôtel Le Marais Romilly Sur Seine Centre est situé dans le quartier d’affaires de Romilly-sur-Seine en
            Champagne-Ardenne.
            <br /><br /> Vous profiterez gratuitement d’une connexion Wi-Fi et d’un parking privé sécurisé sur place. <br />
              <br /> Les chambres insonorisées disposent d’une télévision et d’une salle de bains privative. <br />

                <br /> L’hôtel Le Marais Romilly Sur Seine Centre sert un petit-déjeuner buffet quotidien. Durant votre
                  séjour, vous bénéficierez d’une réduction dans un restaurant voisin.
                  <br />

                    <br /> L’hôtel se trouve à 1,5 km de la gare de Romilly-sur-Seine et à 17 km de Nogent-sur-Seine</p>
          <a className="head-btn" href="#book">Reserver</a>
        </div>
      </header>

      <Services />
      <Book roomsData={roomsData} selectedRoom={room} setSelectedRoom={handleChange}/>
      <Rooms roomsData={roomsData} roomSetter={handleChange}/>

        <Review />
      <footer className= "footer">
        <div className= "footer-container">
          <div>
            <h2>A Propos </h2>
            <p>Toutes nos chambres sont assujétis d'une taxe de séjour de 0.30€ par nuit par personne</p>
            <p>Les avis de nos clients presentés sur ce site sont issue de google review et leur original est disponible en cliquant dessus. Les images représentant les clients sont a titre d'illustration et ne sont pas les photos personnelles des clients.
              Si vous etes l'auteur d'un avis publié et souhaitez le faire retirer, contactez nous par mail a <a href="mailto:lhotelromilly@gmail.com">lhotelromilly@gmail.com</a> ou par telephone au <a href="tel:+33325252510">03 25 25 25 10</a>
            </p> <br /> <br />


            <p>Design adapté de <a href="https://github.com/prabinmagar/hotel-gp-single-page-site">  prabinmagar</a></p>
            <div  >
            <span><a href="" onClick={(event) =>{ event.preventDefault(); setOpenMentions(true);}} >Mentions légales</a>
            <Mentions open={openMentions} setOpen={setOpenMentions}></Mentions>
            </span>
        </div>
        <div >
            <span><a href="" onClick={(event) =>{ event.preventDefault(); setOpenAnnulation(true);}} >Conditions de réservation et annulation</a>
            <Annulation open={openAnnulation} setOpen={setOpenAnnulation}></Annulation>
            </span>
        </div>

    </div>


    <div>
      <h2>Une question ? </h2>
      <div className= "contact-item">
                          <span>
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                          </span>
        <span>
                              <a href="https://goo.gl/maps/W1bC9cLYEvZA8teC7">123 Av. Diderot, 10100 Romilly-sur-Seine </a>
                          </span>
      </div>
      <div className= "contact-item">
                          <span>
                              <FontAwesomeIcon icon={faPhoneAlt} />
                          </span>
        <span>
                              <a href="tel:+33325252510">03 25 25 25 10</a>
                          </span>
      </div>
      <div className= "contact-item">
                          <span>
                              <FontAwesomeIcon icon={faEnvelope} />
                          </span>
        <span>
                              <a href="mailto:lhotelromilly@gmail.com">lhotelromilly@gmail.com</a>
                          </span>
      </div>
        <div className={"contact-item"} >
            <span><FontAwesomeIcon icon={faPersonCircleExclamation} /></span>
            <span><a href="" onClick={(event) =>{ event.preventDefault(); setOpen(true);}} > Mediateur du tourisme </a>
            <Mediateur open={open} setOpen={setOpen}></Mediateur>
            </span>
        </div>
      
    </div>
</div>
</footer>
    </div>
  );
}

export default App;
