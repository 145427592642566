import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import { Form, DateRangePicker, SelectPicker, InputNumber, Button, Schema, Row, Grid, Col, Message } from 'rsuite';
import BookingModal from "./BookingModal";

function Book({ roomsData, selectedRoom, setSelectedRoom }) {
    const [checkinDate, setCheckinDate] = useState("");
    const [checkoutDate, setCheckoutDate] = useState("");
    const [adults, setAdults] = useState(1);
    const [rooms, setRooms] = useState(1);
    const [room, setRoom] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const { beforeToday } = DateRangePicker
    const [formValue, setFormValue] = useState({
        dateRange: [],
        roomType: selectedRoom,
        adults: 1,
        rooms: 1
    });

    useEffect(() => {
        setFormValue(prevValue => ({
            ...prevValue,
            roomType: selectedRoom
        }));
    }, [selectedRoom]);

    const Field = React.forwardRef((props, ref) => {
        const { name, message, label, accepter, error, ...rest } = props;
        return (
            <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
                <Form.ControlLabel>{label} </Form.ControlLabel>
                <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
                <Form.HelpText>{message}</Form.HelpText>
            </Form.Group>
        );
    });

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1)
    let form = React.useRef();

    const handleSubmit = () => {
        console.log("Form submitted:", {
            formValue
        });
        if (!form.current.check()) {
            console.log("error!")
            return;
        }
        const room = roomsData.find(room => room.title === selectedRoom);

        setRoom(room)
        setModalOpen(true)
    };
    const { ArrayType, NumberType, StringType } = Schema.Types;
    const model = Schema.Model({
        dateRange: ArrayType()
            .minLength(2, "La date d'arrivée et de départ doivent etre sélectionnée")
            .isRequired('Ce champs est requis'),
        roomType: StringType().isRequiredOrEmpty('ce champs est requis'),
        adults: NumberType().min(1, 'choisissez un nombre supérieur a 0').isRequired('ce champs est requis'),
        rooms: NumberType().min(1, 'choisissez un nombre supérieur a 0').isRequired('ce champs est requis')
    });

    const roomData = roomsData.map(item => ({ label: item.title, value: item.title }))


    return (<>
        {successMessage !== "" &&
            <Message type="success" showIcon>
                {successMessage}
            </Message>}
        <div className="book" id="book">
            <h2>Reservations</h2>

            <p>

                Pour réserver votre séjour, remplissez le formulaire de réservation
                suivant ou contactez-nous via :
            </p>
            <div className="book-form book-form2">
                <span className="form-item">
                    <a href="tel:+33325252510">
                        <FontAwesomeIcon icon={faPhone} /><br />
                        03 25 25 25 10
                    </a>
                </span>
                <span className="form-item">
                    <a href="mailto:lhotelromilly@gmail.com">
                        <FontAwesomeIcon icon={faAt} /><br />
                        lhotelromilly@gmail.com
                    </a>
                </span>
            </div>
            <div>
                <Form
                    formValue={formValue}
                    onChange={formValue => setFormValue(formValue)}
                    ref={form}
                    model={model}
                >
                    <Grid fluid>
                        <Row>

                                <Col xl={5} lg={4} md={12}>
                                    <label>Date</label><br />
                                    <div className="customDatePickerWidth">
                                        <Field
                                            name="dateRange"
                                            placeholder="Selectionez vos dates"
                                            defaultCalendarValue={[today, tomorrow]}
                                            editable={false}
                                            shouldDisableDate={beforeToday()}
                                            accepter={DateRangePicker}
                                            size="lg"
                                            onChange={(e) => {
                                                setCheckinDate(e[0])
                                                setCheckoutDate(e[1])
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col xl={5} lg={4} md={12}>
                                    <label>Type de chambre:</label> <br />
                                    <div className="customDatePickerWidth">
                                        <Field
                                            name="roomType"
                                            data={roomData}
                                            placeholder="choisir une chambre"
                                            accepter={SelectPicker}
                                            value={selectedRoom}
                                            onChange={setSelectedRoom}
                                            size="lg"
                                        />
                                    </div>
                                </Col>


                                <Col xl={5} lg={4} md={10}>
                                    <label>Nombre de personnes:</label><br />
                                    <Field
                                        name="adults"
                                        min={1}
                                        accepter={InputNumber}
                                        onChange={setAdults}
                                        size="lg"
                                    />
                                </Col>

                                <Col xl={5} lg={4} md={10}>
                                    <label>Nombre de chambres:</label><br />
                                    <Field
                                        name="rooms"
                                        min={1}
                                        accepter={InputNumber}
                                        onChange={setRooms}
                                        size="lg"
                                    />
                                </Col>

                                <Col xl={4} lg={4} md={2}>
                                    <Button type="submit" className="btn" appearance="primary" onClick={handleSubmit} style={{ margin: "27px auto" }}>
                                        Rechercher
                                    </Button>
                                </Col>

                        </Row>
                    </Grid>
                </Form>
            </div>
            <BookingModal showModal={modalOpen} setShowModal={setModalOpen} selectedRoomData={room} selectedRoom={selectedRoom} checkinDate={checkinDate} checkoutDate={checkoutDate} person={adults} nbrooms={rooms} setSuccessMessage={setSuccessMessage} />
        </div>
    </>
    );
}

export default Book;
