import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';


const menuItems = [
    { id: 1, title: "Accueil", link: "#header" },
    { id: 2, title: "Services", link: "#services" },
    { id: 3, title: "Chambres", link: "#rooms" },
    { id: 4, title: "Avis", link: "#customers" },
];
Modal.setAppElement('#root');

function Menu() {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
        <div className="site-nav">
            <span id="nav-btn" onClick={openModal}>MENU <FontAwesomeIcon icon={faBars} /></span>
        </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="sidenav"
            >
              <span className="cancel-btn" id="cancel-btn" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} />
              </span>

                    <ul className="navbar">
                        {menuItems.map((item) => (
                            <li key={item.id}>
                                <a href={item.link}>{item.title}</a>
                            </li>
                        ))}
                    </ul>
            </Modal>
        </div>
    );
}

export default Menu;
