import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import cus3 from '../images/cus3.jpg'
import cus1 from '../images/cus1.jpg'
import cus2 from '../images/cus2.jpg'

const customersData = [
    {
        id: 1,
        rating: 4,
        title: "e recommande cet établissement les yeux fermés.",
        review: "Je recommande cet établissement les yeux fermés. Un Hôtel qui paye pas de mine extérieurement mais la qualité est au rendez-vous. Le personnel est toujours à votre disposition et à votre écoute afin que vous passiez un bon séjour. Souriant et professionnel",
        image: cus3,
        name: "Sébastien",
        location: "https://goo.gl/maps/47x97ggHVTWFTuku9"
    },
    {
        id: 2,
        rating: 5,
        title: "Très bon rapport qualité prix à Romilly-sur-Seine",
        review: "Très bon rapport qualité prix à Romilly-sur-Seine. J'ai séjourné dans cet hôtel deux nuits. A chaque fois c'était très bien. Personnel très acceuillant et chambre parfaite. Très bon rapport qualité prix à Romilly-sur-Seine",
        image: cus1,
        name: "Julien",
        location: "https://goo.gl/maps/Ve2fQmNfJYUiuY2z8"
    },
    {
        id: 3,
        title: "chaleureux et propre",
        rating: 5,
        review: "Hôtel chaleureux propre et très bonne équipe je vous le conseil 👍 👌 ",
        image: cus2,
        name: "Sarah",
        location: "https://goo.gl/maps/Ve2fQmNfJYUiuY2z8"
    }
];

const review = () => {
    return (
        <section className="customers" id="customers">
            <div className="sec-width">
                <div className="title">
                    <h2>Nos clients en parlent...</h2>
                </div>
                <div className="customers-container">
                    {customersData.map(customer => (
                        <div className="customer" key={customer.id}>
                            <a href={customer.location}>
                                <div className="rating">
                                    {Array(customer.rating).fill().map((_, index) => (
                                        <span key={index}><FontAwesomeIcon icon={faStar} /></span>
                                    ))}
                                    {Array(5 - customer.rating).fill().map((_, index) => (
                                        <span key={index}><FontAwesomeIcon icon={faStar} /></span>
                                    ))}
                                </div>
                                <h3>{customer.title}</h3>
                                <p >{customer.review}</p>
                                <img src={customer.image} alt="customer image" />
                                <span>{customer.name}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default review;
