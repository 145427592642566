import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faWifi, faSnowflake, faParking } from "@fortawesome/free-solid-svg-icons";

const servicesData = [
    {
        icon: faUtensils,
        title: "Petit dejeuner",
        description: "Un buffet gourmand pour le petit déjeuner vous est proposé en option avec votre chambre",
    },
    {
        icon: faWifi,
        title: "Wifi gratuit",
        description: "Une connexion wifi est accessible gratuitement depuis toutes les chambres",
    },
    {
        icon: faSnowflake,
        title: "Air climatisé",
        description: "Toutes nos chambres sont équipées d'une unité individuelle d'air climatisé",
    },
    {
        icon: faParking,
        title: "Parking offert",
        description: "Un parking sécurisé de plus de 48 places ainsi qu'un abri pour deux roues sont à disposition gratuitement pour nos clients",
    },
];

function Services() {
    return (
        <section className="services sec-width" id="services">
            <div className="title">
                <h2>services</h2>
            </div>
            <div className="services-container">
                {servicesData.map((service, index) => (
                    <article className="service" key={index}>
                        <div className="service-icon">
              <span>
                <FontAwesomeIcon icon={service.icon} />
              </span>
                        </div>
                        <div className="service-content">
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                        </div>
                    </article>
                ))}
            </div>
        </section>
    );
}

export default Services;
