import { Modal, ButtonToolbar, Button, Placeholder } from 'rsuite';

const Mentions = ({open, setOpen}) => {
    const handleOpen = value => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            <Modal size={"lg"} open={open} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>Mentions Legale</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Propriétaire du site</h4>
                    <p>Le site hotel-romilly.fr est la Propriété de SAS B.A.L.Y au capital social de 10.000€, domicilié au 123 Avenue Diderot, 10100 Romilly-sur-seine et représentée par M. Ahmed BERROUAG en sa qualité de gérant.</p>
                    <h4> SAS B.A.L.Y</h4>
                    <p>
                        Tél.: +33 (0)3 25 25 25 10   <br />
                        E-mail: lhotelromilly@gmail.com <br />
                         RCS Troyes 883 257 842 <br />
                         SIRET  883 257 842 00012 <br />
                         APE 5510Z <br />
                         TVA FR94 883 257 842
                    </p>
                    <h4>Hébergement</h4>
                    <p>
                    Le Site est hébergé par la société : SAS OVH https://www.ovh.com/ <br />
Adresse : 2, rue Kellermann BP 80157 59053 ROUBAIX Cedex 1 <br />
Email: support@ovh.com <br />
Tél. : +33 (0)8 203 203 63 <br />
SIRET : FR79537407926 <br />
Numéro d’immatriculation au Registre du Commerce et des Sociétés de Lille : B 424 761 419 <br />
                    </p>
                    <h4>Collecte des données personnelles</h4>
                    <p>Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertàs, modifiée par la loi n°2004-801 du 6 août 2004, vous disposez d'un droit d'accès, de modification, de rectification et de suppression de votre contribution. Pour cela, écrivez au siège de l'entreprise.</p>
                <h4>Mentions Relatives aux cookies</h4>
                <p><strong>Qu’est-ce qu’un cookie et à quoi sert-il ?</strong>
                Un cookie (ou témoin de connexion) est un fichier texte susceptible d’être enregistré, sous réserve de vos choix, dans un espace dédié du disque dur de votre terminal (ordinateur, tablette …) à l’occasion de la consultation d’un service en ligne grâce à votre logiciel de navigation. Il est transmis par le serveur d’un site internet à votre navigateur. A chaque cookie est attribué un identifiant anonyme. Le fichier cookie permet à son émetteur d’identifier le terminal dans lequel il est enregistré pendant la durée de validité ou d’enregistrement du cookie concerné. Un cookie ne permet pas de remonter à une personne physique.</p>
                <h4>Cookies de Statistiques Google Analytics</h4>
                <p>Ces cookies permettent d’établir des statistiques de fréquentation du site hotel-romilly.fr et de détecter des problèmes de navigation afin de suivre et d’améliorer la qualité de nos services.</p>
             
             <h4>OPPOSITION AU DÉMARCHAGE TÉLÉPHONIQUE</h4>
             <p>
             Conformément à l’article L.223-2 du Code de la consommation, le consommateur qui ne souhaite pas faire l'objet de prospection commerciale par voie téléphonique peut gratuitement s'inscrire sur une liste d'opposition au démarchage téléphonique. Cette liste est accessible via le site suivant : Bloctel (www.bloctel.gouv.fr).
             </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Annuler
                    </Button>
                    <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Mentions;