import React, { useState } from "react";
import {Modal, Form, Button, Schema, Message, Table} from "rsuite";

import axios from 'axios';
import { differenceInDays } from 'date-fns'

const { StringType } = Schema.Types;
const { Column, HeaderCell, Cell } = Table;

const model = Schema.Model({
    firstName: StringType().isRequired("First name is required."),
    lastName: StringType().isRequired("Last name is required."),
    phoneNumber: StringType()
        .pattern(
            /^(\+\d{1,3})?[-.\s]?(\(\d{1,3}\))?[-.\s]?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/,
            "Invalid phone number."
        )
        .isRequired("Phone number is required."),
    email: StringType()
        .isEmail("Invalid email address.")
        .isRequired("Email is required."),
});

const BookingModal = ({ showModal, setShowModal, selectedRoom, checkinDate, checkoutDate, selectedRoomData, person, nbrooms, setSuccessMessage }) => {
    const [formValue, setFormValue] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
    });

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isAvailable] = useState(true);
    const form = React.useRef();
    const [nuits, setNuits] = useState(0)
    const [total, setTotal] = useState(0)
    const [data, setData] = useState([])
     const handleSubmit = async () => {
        if (!form.current.check()) {
            return;
        }

        setLoading(true);

        // Perform booking
        axios.post('https://backend.hotel-romilly.fr/api/booking', { ...formValue, selectedRoom, checkinDate, checkoutDate, person, nbrooms, nuits })
            .then(response => {
                console.log('Booking successful:', response.data);
                setSuccessMessage("Votre demande a ete envoye avec succes")
                // Handle any success actions here, e.g., showing a success message
            })
            setShowModal(false)
            .catch(error => {
                setErrorMessage("Erreur lors de l'envois des informations, veuillez reessayer.");
                // Handle any error actions here, e.g., showing an error message
            });

        setLoading(false);


    };
    const onOpen = () => {
        setSuccessMessage("");
        setErrorMessage("")
        let nbnuits = differenceInDays(checkoutDate, checkinDate)
        setNuits(nbnuits)
        setTotal(nbnuits * selectedRoomData.rate * nbrooms * person)
        setData([{
            room: selectedRoom,
            rate: selectedRoomData.rate + "€ / nuits",
            nbrooms: nbrooms,
            person: person,
            nuits: nbnuits,
        }])

    }
    return (
        <Modal size={"full"} open={showModal} onOpen={()=> onOpen()} onClose={() => {setShowModal(false); setSuccessMessage(""); setErrorMessage("")}}>
            <Modal.Header>
                <Modal.Title>Reservations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Total de votre reservation</h4>
                <Table hover={true} data={data} autoHeight={true} cellBordered={true} fillHeight={false}>
                    <Column key="room" width={300}>
                        <HeaderCell> Chambre </HeaderCell>
                        <Cell dataKey="room"></Cell>
                    </Column>
                    <Column key="rate">
                        <HeaderCell> Prix </HeaderCell>
                        <Cell dataKey="rate"></Cell>
                    </Column>
                    <Column key="nbrooms" width={200}>
                        <HeaderCell> Nombre de chambres </HeaderCell>
                        <Cell dataKey="nbrooms"></Cell>
                    </Column>
                    <Column key="person" width={200}>
                        <HeaderCell> Nombre de personnes </HeaderCell>
                        <Cell dataKey="person"></Cell>
                    </Column>
                    <Column key="nuits" width={200}>
                        <HeaderCell> Nombre de nuits </HeaderCell>
                        <Cell dataKey="nuits"></Cell>
                    </Column>
                    <Column>
                        <HeaderCell> Total </HeaderCell>
                        <Cell >{total} €<sup>*</sup></Cell>
                    </Column>
                </Table>
                <br />
                <p><i><sup>*</sup> Toutes nos chambres sont assujéties à une taxe de séjour supplémentaire de 0.30€ par nuit par personne</i></p>
                <p>Votre demande sera envoyée a notre équipe qui vous recontactera par téléphone pour finaliser la reservation. <br />
                    Le paiement s'effectue lors de votre arrivée a l'hotel</p>

                <h4> Contact </h4>
                { errorMessage !== "" &&
                <Message type="error" showIcon>
                    { errorMessage }
                </Message>
                }

                <Form
                    fluid
                    model={model}
                    formValue={formValue}
                    onChange={setFormValue}
                    ref={form}
                >
                    <Form.Group>
                        <Form.ControlLabel>Prénom</Form.ControlLabel>
                        <Form.Control name="firstName" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Nom</Form.ControlLabel>
                        <Form.Control name="lastName" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Téléphone</Form.ControlLabel>
                        <Form.Control name="phoneNumber" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="email" />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>

                <Button onClick={handleSubmit} className="rs-btn-primary" loading={loading} disabled={!isAvailable}>
                    Reserver
                </Button>
                <Button appearance="subtle" onClick={() => setShowModal(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BookingModal;
