import { Modal, ButtonToolbar, Button, Placeholder } from 'rsuite';

const Mediateur = ({open, setOpen}) => {
    const handleOpen = value => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            <Modal size={"lg"} open={open} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>Mediateur du tourisme</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>QU'EST-CE QUE LA MÉDIATION DU TOURISME ?</h4>
                    <p>La médiation du tourisme est un service gratuit et indépendant proposé pour assister les clients dans d'éventuels litiges à la suite d’un séjour au chez nous.</p>
                    <p>                        Cette médiation est gratuite pour le clients. Il vous suffit de respecter les critères suivants : <br />

                        - Avoir ouvert une réclamation détaillée auprès de la direction du Live Hotels (via un moyen de communication écrite) et avoir gardé une trace de ces échanges <br />

                        - Ne pas avoir obtenu de réponse sous 45 jours satisfaisante. <br /> </p>

                        <p> Après avoir saisi la direction de l’hôtel et à défaut de réponse satisfaisante dans un délai de 45 jours le client peut saisir le Médiateur Tourisme Voyage, dont les coordonnées et modalités de saisine sont disponibles sur son site : <a href="https://www.mtv.travel/">https://www.mtv.travel/</a> <br /> <br />

                        Le médiateur du tourisme disposera de 90 jours pour rendre son avis. <br /> <br />

                        L'hotel du marais s'engage systématiquement à suivre cet avis dès lors que le dossier aura été instruit par le Médiateur avec l'ensemble des deux parties. <br /> <br />

                        La médiation du tourisme est disponible depuis ce lien ou en vous rendant directement sur  <a href="https://www.mtv.travel/">https://www.mtv.travel/</a> ou à l’adresse suivante : Médiation tourisme et voyage, BP 80 303 75 823 Paris Cedex

                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Mediateur;