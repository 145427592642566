import ReactDOM from 'react-dom/client'
import { CustomProvider } from 'rsuite';
import frFr from 'rsuite/locales/fr_FR';
import App from './components/App'
import 'rsuite/dist/rsuite.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <CustomProvider locale={frFr}>
        <App/>
    </CustomProvider>
)