import { Modal, ButtonToolbar, Button, Placeholder } from 'rsuite';

const Annulation = ({open, setOpen}) => {
    const handleOpen = value => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            <Modal size={"lg"} open={open} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>Conditions de réservation et annulation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Les présentes conditions de vente s’adressent à une clientèle individuelle (moins de 10 personnes), en déplacement dans le cadre d’un séjour professionnel ou de loisirs et dont le séjour n’implique pas la réalisation de prestations « Séminaire » ou « Groupe » (location de salle, banquet…) </p>

<p>Toute réservation faite à l’hôtel Le Marais et correspondant aux critères précédemment énoncés signifie l’adhésion aux présentes conditions générales de vente et l’acceptation complète et sans réserve de leurs termes.</p>

<h4>ARTICLE 1 : Capacité</h4>
<p>
Tout client effectuant une réservation reconnaît avoir la capacité de contracter, à savoir être majeur et ne pas être placé sous tutelle ou curatelle.
</p>

<h4>ARTICLE 2 : Prix</h4>
<p>
Les tarifs sont indiqués en euros et TTC. <br />
Ils ne comprennent que les prestations strictement mentionnées au moment de la réservation ou dans le courrier de confirmation. <br />
Au tarif mentionné lors de la réservation seront ajoutées les prestations consommées en plus lors du séjour ainsi que le montant de la taxe de séjour. <br />
</p>

<h4>ARTICLE 3 : Conditions de réservation
</h4>
<p>
Afin de proceder à la réservation, un moyen de contact vous est demandé dans le formulaire du site, un agent d'acceuil vous recontactera afin de confirmé la reservation. <br />
Afin de garantir la réservation, un numéro de carte bancaire est demandé au moment de la réservation lors de l'appel telephonique. <br />
Il ne s'agit que d'un moyen de garantir la réservation, le paiement s'effectuera sur place au moment de l'arrive. <br />
La carte bancaire ne sera débitée que dans les situations décrites dans les articles 7, 8 et 9.
</p>

<h4>ARTICLE 4 : Prise en charge
</h4>
<p>
Dans le cadre de la prise en charge du séjour du client par un quelconque organisme (entreprise du client, organisme de voyage…), un fax ou un email de prise en charge devra être envoyé à l’hôtel par ledit organisme, avant l’arrivée du client. <br />
Sur ce document devra figurer un récapitulatif de la réservation (nom de client, date, montant de la chambre réservée…), le détail des prestations prises en charge par l’organisme (hébergement, petit-déjeuner, extras…) ainsi que l’adresse à laquelle devra être adressée la facture.<br />
En cas de non réception de ce document avant la fin du séjour du client, celui-ci devra s’acquitter personnellement, au moment de son départ, des sommes facturées relatives à son séjour. <br />
</p>


<h4>ARTICLE 5 : Mise à disposition des chambres
</h4>
<p>
Les chambres sont mises à votre disposition à partir de 15h le jour de l’arrivée, l'acceuil ferme a 21h.<br />
Elles devront être libérées à 11h au plus tard le jour du départ en semaine, et 12h le week-end. <br />
Toute réservation est maintenue jusqu’à 18h le jour de l’arrivée. Merci de bien vouloir nous prévenir en cas d’arrivée tardive. <br />
Passé 18h et dans le cas où le client n’aurait prévenu de son arrivée tardive, l’hôtel se réserve le droit d’annuler sa réservation.
</p>

<h4>ARTICLE 6 : Conditions de paiement
</h4>
<p>
Le paiement du séjour se fait à la réception de l’hôtel au moment de l'arrivé du client, sur présentation d’une facture éditée par l'hôtel.<br />
Les frais engagés par l'hôtel lors de procédures de recouvrement de ses créances sont à la charge du client concerné.
</p>
<h4>ARTICLE 7 : Annulations
</h4>
<p>
En cas d'annulation ou de modification jusqu'à 2 jours avant la date d'arrivée, l'hôtel ne prélève pas de frais sur la carte de crédit fournie.<br />
En cas d'annulation ou de modification tardive (moins de 24 heures), l'hôtel prélève, sur la carte de crédit fournie, 100% du montant de la première nuit.
</p>
<h4>ARTICLE 8 : No Show
</h4>
<p>
En cas de non présentation du client à la date prévue, l’hôtel prélève, sur la carte de crédit fournie, le montant total de la réservation.
</p>

<h4>ARTICLE 9 : Vols et consommations non déclarées
</h4>
<p>
Pour tout vol ou consommation non déclarée au moment du départ, l’hôtel Le Marais se réserve le droit de facturer et de prélever le montant des sommes volées ou non déclarées sur la carte bancaire laissée en garantie au moment de la réservation.</p>

<h4>ARTICLE 10 :  Force majeure
</h4>
<p>
Par « cas de force majeure » s’entend tout évènement indépendant de la volonté de l’hôtel et présentant un caractère imprévisible et insurmontable rendant impossible la réalisation de tout ou partie des prestations réservées par le client auprès de l’hôtel (catastrophe climatique, grève, émeute…).
<br /> 
Face à un cas de force majeure, l’hôtel Le Marais ne saurait être tenu responsable de la non réalisation desdites prestations.
</p>
<h4>ARTICLE 11 :  Litiges et juridiction compétent     
</h4>
<p>
Pour tout litige n’ayant pu être réglé à l’amiable, la juridiction compétente pour instruire le dossier sera celle dont dépend l’hôtel Le Marais.
</p>

                       </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Annuler
                    </Button>
                    <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Annulation;